import React from "react";
import { useSelector } from "react-redux";

import { Box, Flex, Image } from "atoms";
import { GetLink } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const HeaderLogo = () => {  

  const { headerLogos } = useSelector(state => state.data);

  return (
    <>
      <Flex        
        p="1rem"                     
        alignItems="center"
        justifyContent="center"
        style={{gap: "1.2rem"}}
        className="alpana-border"
      >
        <div className="left-right"/>
        {headerLogos?.map((logo) => (
          <GetLink
            key={logo?.id}
            url={logo?.url}
          >
            <Box              
              width={logo?.sx}
              height={{xs: "4rem", md: "10rem"}}              
            >
              <Image
                src={getStrapiMediaUrl(logo?.image?.url)}
                alt={logo?.label}
                width="100%"
                height="100%"
                objectFit="contain"
              />              
            </Box>
          </GetLink>
        ))}              
      </Flex>
    </>
  )
}